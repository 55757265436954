<template>
  <el-form
    :model="directiveForm"
    :rules="rules"
    ref="directiveForm"
    label-width="100px"
    class="directiveForm"
  >
    <el-form-item label="指令类型名称名称" prop="type_name">
      <el-input
        v-model="directiveForm.type_name"
        placeholder="请输入指令名称"
      ></el-input>
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click="submitForm('directiveForm')">
        确认保存
      </el-button>
      <el-button @click="$router.push('/privateResource/directiveTypes')">
        取消
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      directiveForm: {
        type_name: "",
      },
      rules: {
        type_name: [
          { required: true, message: "请输入指令类型名称", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapState(["directiveTypesList"]),
  },
  mounted() {
    this.$store.dispatch("getDirectiveTypesAction", {});
    this.initDirectiveInfo();
  },
  methods: {
    initDirectiveInfo() {
      this.$request
        .getDirectiveTypeInfo({ id: this.$route.query.id })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          for (const key in this.directiveForm) {
            this.directiveForm[key] = (res.data || {})[key];
          }
        });
    },
    submitForm() {
      this.$refs.directiveForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        const { id } = this.$route.query;
        const params = {
          ...this.directiveForm,
          id,
        };
        this.$request.addOrUpdateDirectiveType(params).then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.$message({
            message: `${id ? "修改" : "新建"}成功`,
            type: "success",
          });
          this.$router.push("/privateResource/directiveTypes");
        });
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss">
.directiveForm {
  background-color: #fff;
  padding: 24px 30% 24px 24px;
  .el-form-item__label {
    width: 30% !important;
  }
  .el-form-item__content {
    margin-left: 30% !important;
    .el-select {
      display: block;
    }
  }
}
</style>